<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" :tip="'正在分析 '+ dataValue.modelName +' 的全部数据信息，请稍等 ...'"></a-spin>
  <div v-if="dataValue.loading === false" class="show">
    <div class="l">
      <div class="title">
        <img v-if="dataValue.modelObj['defaultImg'] !== ''" class="img" :alt="dataValue.modelName" :src="dataValue.modelObj['imgServer'] +'150x'+ dataValue.modelObj['defaultImg']" />
        <div class="m">
          <div>{{ dataValue.modelObj['modelName'] }}</div>
          <div>{{ dataValue.modelObj['manufacturerName'] }}</div>
          <div>{{ dataValue.modelObj['description'] }}</div>
        </div>
      </div>
      <div class="imgs" v-if="dataValue.modelObj['images'].length > 0">
        <template v-for="(aImg,i) in dataValue.modelObj['images']">
          <img v-if="i<5" :src="dataValue.modelObj['imgServer'] +'50x'+ aImg['url']" :alt="aImg['description']" />
        </template>
      </div>
      <div class="patr">
        <div v-if="dataValue.modelObj['specifications'].length === 0" class="noAtr">
          无当前型号的属性数据
        </div>
        <div class="atr" v-for="aRow in dataValue.modelObj['specifications']">
          <div>{{aRow['typeEnglish']}}：</div>
          <div>{{aRow['value']}}</div>
        </div>
      </div>
    </div>
    <div class="r">
      <div class="priceC">
          <div>
            <span>模拟成本价:</span>
            <span>¥ {{dataValue.modelObj['priceC']['CBBJRMBPrice'].toFixed(5)}}</span>
          </div>
        <div>
          <span>模拟原厂价:</span>
          <span>¥ {{dataValue.modelObj['priceC']['YCRMBPrice'].toFixed(5)}}</span>
        </div>
        <div>
          <span>模拟市场价:</span>
          <span>¥ {{dataValue.modelObj['priceC']['SCRMBPrice'].toFixed(5)}}</span>
        </div>
      </div>
      <div id="marketPriceDiv"></div>
      <div id="platformPriceDiv"></div>
      <div id="saveDiv">
        <div class="tDiv">C - 存储价格的统计（¥）</div>
        <div class="l">
          <div id="savePriceDiv"></div>
          <div class="savePriceDivList">
            <div v-for="aRow in dataValue.modelObj['unionInfo']['savePriceCount']">
              <div>{{aRow['priceTypeName']}}:</div>
              <div>{{aRow['count']}}条</div>
              <div>{{aRow['createTime']}}</div>
            </div>
          </div>
        </div>
        <div class="r">
          <template v-for="(aRow, i) in dataValue.modelObj['unionInfo']['savePriceList']">
            <div v-if="i < 14" >
              <div>{{aRow['priceTypeName']}}:</div>
              <div>¥{{aRow['rmbPrice'].toFixed(4)}}</div>
              <div>{{aRow['createTime'].substring(0,10)}}</div>
            </div>
          </template>
        </div>
      </div>
      <div id="bomListDiv"></div>
      <div class="docList">
        <template v-for="(aDoc, i) in dataValue.modelObj['documents']">
          <div><a :href="aDoc['url']" :title="aDoc['description']" target="_blank">{{i+1}}、{{aDoc['type'] }},{{ aDoc['description']}} </a></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import * as echarts from 'echarts';




export default {
  name : "bi_model",
  props: ['model_name'],
  emits : ['goClose'],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : true,
      modelName : props.model_name,
      modelObj:{},
      detail:{
        input : {
          modelName : "",
          showUnionInfo : 1
        }
      }
    })

    let allEvent = {

      getDetailHttp : ()=>{

        //dataValue.detail.input.modelName = "STM32F407VET6";
        dataValue.detail.input.modelName = dataValue.modelName;

            proxy.post('/web/product/detail', dataValue.detail.input).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.modelObj = res.data;
            dataValue.loading = false;

            if( dataValue.modelObj['modelName'] !== '' ) allEvent.union.init();

          }
        })
      },


      union : {

          // 存储价格
          savePriceUnion : ()=>{

            let divObj = document.getElementById("savePriceDiv");

            if( dataValue.modelObj['unionInfo']['savePriceList'].length === 0 ){
              document.getElementById("saveDiv").innerHTML = "当前型号还没有存储价格信息。";
              document.getElementById("saveDiv").style.backgroundColor = "#eeeeee";
              document.getElementById("saveDiv").style.lineHeight = "300px";
              return;
            }

            let echart = echarts;
            let chart = echart.init(divObj);

            let data1 = [];
            dataValue.modelObj['unionInfo']['savePriceCount'].forEach((am)=>{
              data1.push({ value:am['count'], name:am['priceTypeName']+'数量' });
            });


            chart.setOption({
              tooltip: {
                trigger: 'item'
              },
              series: [
                {
                  type: 'pie',
                  radius: '50%',
                  data: data1
                }
              ]

            });
          },

          // 平台价格
          platformPriceUnion : ()=>{

            let divObj = document.getElementById("platformPriceDiv");

            if( dataValue.modelObj['unionInfo']['platformPriceList'].length === 0 ){
              divObj.innerHTML = "没有当前型号记录的平台价格数据。";
              divObj.style.backgroundColor = "#eeeeee";
              return;
            }


            let echart = echarts;
            let chart = echart.init(divObj);

            let data1 = [], data2 = [];

            dataValue.modelObj['unionInfo']['platformPriceList'].forEach((am)=>{
              data1.splice( 0, 0, am['number'] );
              data2.splice( 0, 0, am['rmbPrice'].toFixed(2) );
            });


            chart.setOption({

              title:
                  {
                    left: 'center',
                    text: 'B - 平台购买量对应价格（¥）'
                  }
              ,
              grid: {
                top: '20%',
                left: '3%',
                right: '3%',
                bottom: '13%',
                containLabel: true
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                formatter: function (params) {
                  let tar = params[0];
                  return '¥'+ tar.value +'<br /><br />购买数量：'+ tar.name +'个';
                }
              },
              color:[ '#92cc75' ],
              xAxis: {
                type: 'category',
                data: data1,
                axisLabel: { formatter: '{value}个'}
              },
              yAxis: {
                type: 'value',
                axisLabel: { formatter: '¥{value}'}
              },
              series: [
                {
                  data: data2,
                  type: 'bar',
                  showBackground: true,
                  backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                  },
                  label: {
                    show: true
                  },
                }
              ]
            });


          },

          // 市场价格体系
          marketPriceUnion : ()=>{

            let divObj = document.getElementById("marketPriceDiv");

            if( dataValue.modelObj['unionInfo']['marketPriceList'].length === 0 ){
              divObj.innerHTML = "没有当前型号记录的市场价格数据。";
              divObj.style.backgroundColor = "#eeeeee";
              return;
            }


            let echart = echarts;
            let chart = echart.init(divObj);

            let data1 = [], data2 = [];

            dataValue.modelObj['unionInfo']['marketPriceList'].forEach((am)=>{
              data1.push( am['createTime'] );
              data2.push( am['rmbPrice'] );
            });


            chart.setOption({

                title:
                  {
                    left: 'center',
                    text: 'A - 市场价格走势历史走势（¥）'
                  }
                ,
                grid: {
                  top: '20%',
                  left: '3%',
                  right: '6%',
                  bottom: '13%',
                  containLabel: true
                },
                xAxis: { type: 'category', data: data1 },
                yAxis: { type: 'value',  axisLabel: { formatter: '¥{value}'} },
                series: {
                  data: data2,
                  type: 'line',
                  smooth: true,
                  markPoint: {
                    data: [
                      { type: 'max', name: 'Max' },
                      { type: 'min', name: 'Min' }
                    ]
                  },
                  markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                  }
                }
            });


          },

          // BomList记录
          bomListUnion : ()=>{

            let divObj = document.getElementById("bomListDiv");

            if( dataValue.modelObj['unionInfo']['nodeCountNumber'].length === 0 ){
              divObj.innerHTML = "没有当前型号记录的往来数据。";
              divObj.style.backgroundColor = "#eeeeee";
              return;
            }


            let echart = echarts;
            let chart = echart.init(divObj);

            let data1 = [], data2 = [], data3 = [];

            dataValue.modelObj['unionInfo']['nodeCountNumber'].forEach((am)=>{
              data1.push( am['modelMonth'] );
              data2.push( am['count'] );
              data3.push( am['number'] );
            });


            chart.setOption({

              title : {
                left : 'center',
                text : 'D - BOM来往记录统计'
              },
              grid : {
                top : '20%',
                left : '3%',
                right : '6%',
                bottom : '5%',
                containLabel : true
              },

              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              xAxis: [
                {
                  type: 'category',
                  axisTick: { show: false },
                  data: data1
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '次数',
                  type: 'bar',
                  emphasis: {
                    focus: 'series'
                  },
                  data: data2
                },
                {
                  name: '数量',
                  type: 'bar',
                  emphasis: {
                    focus: 'series'
                  },
                  data: data3
                }
              ]


            });


          },

          init : ()=>{


            let timer = setInterval(()=>{
              if( document.getElementById("marketPriceDiv") !== null &&
                  document.getElementById("platformPriceDiv") !== null &&
                  document.getElementById("savePriceDiv") !== null &&
                  document.getElementById("bomListDiv") !== null ){
                timer && clearTimeout(timer);
                allEvent.union.marketPriceUnion();
                allEvent.union.platformPriceUnion();
                allEvent.union.savePriceUnion();
                allEvent.union.bomListUnion();
              }
            }, 300);
          }
      }




    }


    allEvent.getDetailHttp();


    return {
      dataValue
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.show{ padding-bottom: 100px; float: left; clear: both; width: 100%; }
.show .l{ float:left; width: 350px; }
.show .l .noAtr{ background-color: #eeeeee; border-radius: 3px; text-align: center; line-height: 300px; }
.show .l .title{ float: left; clear:both; width: 100%; padding: 0 15px 15px 15px; }
.show .l .title .img{ border-radius: 5px; float: left; width: 120px; }
.show .l .imgs{  margin: 5px 0 5px 0; }
.show .l .imgs img{  margin: 5px 0 5px 15px; border-radius: 15%; }
.show .l .title .m{ float: right; width: 180px; }
.show .l .title .m > div:nth-child(1){ color: #1a5f91; font-size:18px; margin-bottom: 2px; }
.show .l .title .m > div:nth-child(2){ color: #333333; font-size:14px; width: 100%; padding-bottom: 3px;
  border-bottom: 1px solid #cccccc; }
.show .l .title .m > div:nth-child(3){ color: #666666; font-size: 12px; line-height: 16px; margin-top: 7px; }
.show .l .patr{ background-color:#efffff; float: left; clear: both; width: 100%; border-radius: 3px; margin-top: 20px; }
.show .l .atr{ border-bottom: 1px solid #eeeeee; width:calc(100% - 30px); clear: both; float: left;
  line-height: 40px; margin:0 15px 0 15px;  }
.show .l .atr > div:nth-child(1){ float: left; }
.show .l .atr > div:nth-child(2){ float: right; color: #666666; }
.show .r{ float:right; width:calc( 100% - 370px); background-color: #ffffff; }
.show .r #marketPriceDiv { margin-top: 50px; width:100%; height:300px; background-color: #ffffff; text-align: center; line-height:300px; float: right; }


.show .r #bomListDiv { margin-top: 80px; width:100%; height:250px; background-color: #ffffff; text-align: center; line-height:250px; float: right; }

.show .r #platformPriceDiv { width:100%; height:280px; background-color: #ffffff; text-align: center; line-height:280px;
  margin-top: 80px;float: right; clear: both; }
.show .r .priceC{ float: left; clear: both; width: 100%; padding-bottom: 20px;
  border-bottom: 1px solid #cccccc; margin-bottom: 20px; }
.show .r .priceC > div{ width: 33%; float: left; text-align: center; line-height: 20px; }
.show .r .priceC > div > span:nth-child(1){ padding-right: 10px; font-size: 14px; }
.show .r .priceC > div > span:nth-child(2){ padding-right: 10px; font-size: 14px; color: #225DDC; }

#saveDiv{ width: 100%; margin-top: 60px; float: left; clear: both; text-align: center; }
#saveDiv .tDiv{ width: 100%; text-align: center; font-size: 18px; }
#saveDiv .l{ float: left; width: 416px; }
#saveDiv .l #savePriceDiv{ width: 400px; height: 300px; font-weight: bold; }
#saveDiv .l .savePriceDivList{ float: left; clear: both; color: #333333; }
#saveDiv .l .savePriceDivList > div{ float: left; clear: both; margin-left:55px; line-height: 30px;  border-bottom: 1px solid #eeeeee; }
#saveDiv .l .savePriceDivList > div > div:nth-child(1){ float: left; width: 88px; }
#saveDiv .l .savePriceDivList > div > div:nth-child(2){ float: left; width: 70px; text-align:center; }
#saveDiv .l .savePriceDivList > div > div:nth-child(3){ float: left; }
#saveDiv .r{ float: right; width: 280px; height: 300px; padding-top: 59px; margin-right: 25px; }
#saveDiv .r > div{ float: right; clear: both; width: 100%; line-height: 25px; color: #666666; border-bottom: 1px solid #eeefff; margin-right: 15px; }
#saveDiv .r > div > div:nth-child(1){ float: left; width: 80px; text-align: left; }
#saveDiv .r > div > div:nth-child(2){ float: left; width: 100px; text-align:right; color: #225DDC; }
#saveDiv .r > div > div:nth-child(3){ float: right; }
.docList{ width: clac( 100% - 60px ); clear: both; float: left; margin: 100px 30px 0 30px; }
.docList > div{ clear: both; float: left; line-height: 30px; }
.docList > div > a:hover{ color: red; }
</style>